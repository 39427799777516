import React from 'react';
import { useStaticQuery, graphql, navigate } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

export default function Footer() {
  const data = useStaticQuery(graphql`
    query FooterQuery {
      file(relativePath: { eq: "Magnus-1.jpg" }) {
         childImageSharp {
           gatsbyImageData(layout: CONSTRAINED)
        }
      }
    }
  `)
  let img = data.file.childImageSharp.gatsbyImageData;
  return(
    <footer className="footer basePad">
      <GatsbyImage image={img} alt="Profile articstic picture of Magnus Ingemund Kjelstad" onClick={(() => navigate("/"))}/>
      <div className="contactInfo">
        <h2>Contact</h2>
        <p>I read my emails here</p>
        <div className="btn"><a href="mailto:mikjelstad@gmail.com"><p>mikjelstad@gmail.com</p></a></div>
        <p className="copyright">{`© ${new Date().getFullYear()} Magnus Ingemund Kjelstad`}</p>
      </div>
    </footer>
  )
}
