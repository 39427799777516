import * as React from "react"
import Nav from "../components/Nav"
import Footer from "../components/Footer"

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath

  return (
    <div className="global-wrapper" data-is-root-path={isRootPath}>
      {
        isRootPath ? '' : <Nav />
      }
        <main>{children}</main>
      {
        location.pathname === "/bio" || location.pathname === "/media"  ? <Footer /> : ''
      }
    </div>
  )
}

export default Layout
