import React from 'react';
import {Link} from 'gatsby';
import {GiCalendar, GiSoundWaves} from 'react-icons/gi'
import {FaCameraRetro} from 'react-icons/fa'
import {FaInfo} from 'react-icons/fa'

const NavLinks = () => {
  return (
    <ul className="flex basePad">
      <li>
        <Link to="/bio" activeClassName="activeNav">
          <FaInfo size={24} />
        </Link>
      </li>
      <li>
        <Link to="/images" activeClassName="activeNav">
          <FaCameraRetro size={22} />
        </Link>
      </li>
      <li>
        <Link to="/calendar" activeClassName="activeNav">
          <GiCalendar size={28} />
        </Link>
      </li>
      <li>
        <Link to="/media" activeClassName="activeNav">
          <GiSoundWaves size={28} />
        </Link>
      </li>
    </ul>
  )
}
export default NavLinks;
